import React from "react";
import { Button, Form, Icon } from "semantic-ui-react";

import styles from "./contact-form-foundation.module.css";
import colors from "../colors";

const ContactFormFoundation = ({ id }) => (
  <div
    className={styles.contentAreaWrapper}
    style={{ backgroundColor: colors.foundation.bg }}
  >
    <Button className={styles.anchor} id="contact-form-foundation" aria-hidden="true"/>
    <div className={styles.contentAreaInnerWrapper}>
      <h2>REQUEST MORE INFORMATION</h2>
      <p>Register your interest and we'll get back to you</p>
      <Form action={`https://formspree.io/f/${id}`} method="POST">
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="name">Your Name</label>
            <input name="name" id="name" placeholder="Your Name" aria-label="Your Name" required />
          </Form.Field>
          <Form.Field>
            <label htmlFor="email">Email</label>
            <input name="email" id="email" aria-label="Email" placeholder="Email" required type="email" />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="organisation">Organisation Name</label>
            <input name="organisation" id="organisation" aria-label="Organisation" placeholder="Organisation" required />
          </Form.Field>
          <Form.Field>
            <label htmlFor="phone">Phone</label>
            <input name="phone" id="phone" aria-label="Phone" placeholder="Phone" required />
          </Form.Field>
        </Form.Group>

        <Form.TextArea label="Your Message" name="message" rows={7} required />
        <Button
          type="submit"
          className={styles.submitButton}
          style={{
            backgroundColor: colors.foundation.cta
          }}
          floated="right"
        >
          Send Request <Icon name="right arrow" />
        </Button>
      </Form>
    </div>
  </div>
);

export default ContactFormFoundation;
