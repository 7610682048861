import React, { useState, useEffect } from "react";
import { Container, Grid, Image } from "semantic-ui-react";

import LayoutInner from "../components/layout/layout-inner";
import SEO from "../components/seo/seo";
import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";

import HeadingOverImage from "../components/heading-over-image/heading-over-image";
import BodyContent from "../components/body-content/body-content";
import FeatureList from "../components/feature-list/feature-list";
import CTA from "../components/cta";
import ContactFormFoundation from "../components/contact-form-foundation/contact-form-foundation";
import foundationStyle from "./foundation.module.css";
import CookieUtils from '../utils/cookie-utils';

const Page = () => {
  const [urlParams, setUrlParams] = useState('');

  useEffect(() => {
    setUrlParams(CookieUtils.analyticsConsentGiven() ? '' : '?dnt=1');
  }, []);

  return (
    <LayoutInner>
      <SEO
          title="The Rocksteady Foundation"
          keywords={[`rocksteady`]}
          description="The Rocksteady Foundation provides free music sessions for charities across the UK, allowing some of the most vulnerable young people to experience the benefits of music."
      />

      <Container className={foundationStyle.foundationContainer}>
        <SecondaryNavBar
          product="foundation"
          title="The Rocksteady Foundation"
          titlePath="/foundation/"
          titleActive={true}
          links={[]}
          cta="Request more information"
          ctaMobile="Request info"
          ctaLink="#contact-form-foundation"
        />

        <HeadingOverImage
          text="A mission to make a difference with music"
          img="/images/foundation/bg_1.jpg"
          siteSection="foundation"
        />

        <FeatureList
          siteSection="foundation"
          features={[
            {
              title: "Free workshops for those in need",
              text:
                "The Rocksteady Foundation organises free sessions  for children and young people in charities and support groups across the UK, allowing some of the most vulnerable in society to enjoy the wellbeing, fun and educational benefits of playing music in a band."
            },
            {
              title: "Opportunities for everyone",
              text:
                "We work with special schools, charities, children’s hospitals, hospices, women’s refuges and groups supporting young carers and looked after children. If your organisation supports young people in difficult situations, we’d love to help."
            },
            {
              title: "Music can make a difference",
              text:
                "Spending a day making music and having fun can offer valuable respite whilst having a positive impact on self-esteem and wellbeing."
            },
            {
              title: "Free lessons in schools",
              text:
                "We give one bursary place free to every school we teach in so that more children have the opportunity to enjoy the benefits of making music, whatever their circumstances."
            }
          ]}
        />
        <HeadingOverImage
          text="Changing Lives"
          img="/images/foundation/bg_3.jpg"
          siteSection="foundation"
        />

        <BodyContent>
          <Grid stackable padded>
            <Grid.Row reversed="computer">
              <Grid.Column width={8}>
                <a href="https://www.bbc.co.uk/news/av/uk-england-hampshire-48610913/the-romsey-young-carers-using-rock-music-as-respite">
                  <Image src="/images/foundation/young_carers.jpg" fluid />
                </a>
              </Grid.Column>
              <Grid.Column width={8}>
                <h3>Young Carers Become Rock Stars</h3>
                <p>
                  Romsey Young Carers used rock music as respite for the many
                  children who don’t usually get the time to pursue hobbies
                  because they’re looking after family members with illnesses or
                  disabilities.
                  <br />
                  <br />
                </p>
                <CTA
                  siteSection="foundation"
                  ctaLink="https://www.bbc.co.uk/news/av/uk-england-hampshire-48610913/the-romsey-young-carers-using-rock-music-as-respite"
                  ctaText="Watch the BBC Video"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </BodyContent>

        <BodyContent>
          <Grid stackable padded>
            <Grid.Row>
              <Grid.Column width={8}>
                <div className="videoWrapper">
                  <iframe
                    width="560"
                    height="315"
                    src={`https://player.vimeo.com/video/518664764${urlParams}`}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title="Foo Fighters and Rocksteady"
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <h3>Foo Fighters Inspire A New Generation</h3>
                <p>
                  We teamed up with the US rock legends to give over 1,000
                  children across 10 locations a free live music experience, where
                  they learnt to play a Foo fighters song in just 30 minutes.
                  <br />
                  <br />
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </BodyContent>

        <BodyContent>
          <Grid stackable padded>
            <Grid.Row reversed="computer">
              <Grid.Column width={8}>
                <Image src="/images/foundation/belgium.jpg" fluid />
              </Grid.Column>
              <Grid.Column width={8}>
                <h3>Refugees In Belgium Integrate With Locals</h3>
                <p>
                  Five of Rocksteady’s pro musicians volunteered at the Routes
                  Music Festival in Brussels, running free workshops for refugee
                  children and the local community to bring them closer together.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </BodyContent>

        <HeadingOverImage
          text="Key Notes"
          img="/images/foundation/bg_2.jpg"
          siteSection="foundation"
        />

        <FeatureList
          siteSection="foundation"
          features={[
            { title: "Free workshops for children in difficult situations" },
            { title: "In partnership with charitable organisations" },
            { title: "Empowers children to learn an instrument" },
            { title: "Builds confidence and self-esteem" },
            { title: "Includes 1,000+ bursaries for schools" }
          ]}
        />

        <BodyContent>
          <ContactFormFoundation id="xoqpjlvq" />
        </BodyContent>
      </Container>
    </LayoutInner>
  );
}

export default Page;
